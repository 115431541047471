import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Field, ErrorMessage } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { object, string } from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { parseTrackingCookie } from '../TrafficSource/utils';

const intialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  company: '',
  email: '',
  hearAbout: '',
  comments: '',
};

const contactUsSchema = object().shape({
  firstName: string().required().label('First Name'),
  lastName: string().required().label('Last Name'),
  phone: string().required().label('Phone Number'),
  company: string().required().label('Company'),
  email: string().email().required().label('Email'),
});

const ContactUsForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormSubmit = async (values, { setStatus }) => {
    try {
      const tracking = parseTrackingCookie('traffic_source');

      const captchaToken = await executeRecaptcha('contact_us');
      const response = await fetch('/.netlify/functions/leadgen', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...values, tracking, captchaToken }),
      });

      if (!response.ok) {
        throw new Error();
      }

      navigate('/thank-you/');
    } catch (err) {
      setStatus(
        'We apologize, an error occurred while submitting your form. If this problem persists, please contact us at webmaster.',
      );
    }
  };

  return (
    <Formik
      initialValues={intialValues}
      onSubmit={handleFormSubmit}
      validationSchema={contactUsSchema}
    >
      {({ handleSubmit, isSubmitting, errors, touched, status }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Form.Group
                as={Col}
                md="6"
                controlId="controlFirstName"
                className="mb-3"
              >
                <Form.Label>First Name*</Form.Label>
                <Field
                  as={Form.Control}
                  type="text"
                  name="firstName"
                  size="lg"
                  isInvalid={errors.firstName && touched.firstName}
                />
                <ErrorMessage
                  name="firstName"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                controlId="controlLastName"
                className="mb-3"
              >
                <Form.Label>Last Name*</Form.Label>
                <Field
                  as={Form.Control}
                  type="text"
                  name="lastName"
                  size="lg"
                  isInvalid={errors.lastName && touched.lastName}
                />
                <ErrorMessage
                  name="lastName"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                md="6"
                controlId="controlPhone"
                className="mb-3"
              >
                <Form.Label>Phone Number*</Form.Label>
                <Field
                  as={Form.Control}
                  type="tel"
                  name="phone"
                  size="lg"
                  isInvalid={errors.phone && touched.phone}
                />
                <ErrorMessage
                  name="phone"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                controlId="controlCompany"
                className="mb-3"
              >
                <Form.Label>Company*</Form.Label>
                <Field
                  as={Form.Control}
                  type="text"
                  name="company"
                  size="lg"
                  isInvalid={errors.company && touched.company}
                />
                <ErrorMessage
                  name="company"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                md="6"
                controlId="controlEmail"
                className="mb-3"
              >
                <Form.Label>Email Address*</Form.Label>
                <Field
                  as={Form.Control}
                  type="email"
                  name="email"
                  size="lg"
                  isInvalid={errors.email && touched.email}
                />
                <ErrorMessage
                  name="email"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                controlId="controlHearAbout"
                className="mb-3 d-none d-lg-block"
              >
                <Form.Label>Where did you hear about us?</Form.Label>
                <Field name="hearAbout">
                  {({ field }) => {
                    return (
                      <Form.Select
                        size="lg"
                        isInvalid={errors.hearAbout && touched.hearAbout}
                        {...field}
                      >
                        <option value="" aria-label="Choose an option" />
                        <option value="Search engine (Google, Bing, etc.)">
                          Search engine (Google, Bing, etc.)
                        </option>
                        <option value="Blog or publication">
                          Blog or publication
                        </option>
                        <option value="Clicked on an ad">
                          Clicked on an ad
                        </option>
                        <option value="Social media">Social media</option>
                        <option value="Recommended by a friend or colleague">
                          Recommended by a friend or colleague
                        </option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    );
                  }}
                </Field>
                <ErrorMessage
                  name="hearAbout"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="controlComments">
                <Form.Label>How can we help?</Form.Label>
                <Field name="comments">
                  {({ field }) => {
                    return (
                      <Form.Control
                        as="textarea"
                        {...field}
                        style={{ height: '125px' }}
                      />
                    );
                  }}
                </Field>
              </Form.Group>
            </Row>

            <Row className="d-lg-none">
              <Col>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="controlHearAbout"
                  className="mb-3"
                >
                  <Form.Label>Where did you hear about us?</Form.Label>
                  <Field name="hearAbout">
                    {({ field }) => {
                      return (
                        <Form.Select
                          size="lg"
                          isInvalid={errors.hearAbout && touched.hearAbout}
                          {...field}
                        >
                          <option value="" aria-label="Choose an option" />
                          <option value="Search engine (Google, Bing, etc.)">
                            Search engine (Google, Bing, etc.)
                          </option>
                          <option value="Blog or publication">
                            Blog or publication
                          </option>
                          <option value="Clicked on an ad">
                            Clicked on an ad
                          </option>
                          <option value="Social media">Social media</option>
                          <option value="Recommended by a friend or colleague">
                            Recommended by a friend or colleague
                          </option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="hearAbout"
                    component={Form.Control.Feedback}
                    type="invalid"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-between mt-4">
              <Col md="auto">
                <div id="re-captcha-container" />
              </Col>
              <Col md="auto" className="mt-4 mt-md-0">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="w-100"
                >
                  {isSubmitting ? 'Sending...' : 'Send'}
                </Button>
              </Col>
            </Row>

            {status && (
              <Alert
                variant="danger"
                className="mt-4 p-4 text-white"
                transition={false}
              >
                {status}
              </Alert>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactUsForm;
