import React from 'react';
import Container from 'react-bootstrap/Container';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import ContactUsForm from '../components/ContactUsForm/ContactUsForm';

const ContactUsPage = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_CAPTCHA_SITE_ID}
      container={{
        element: 're-captcha-container',
        parameters: {
          badge: 'inline',
        },
      }}
    >
      <Seo
        title="Contact Us · Medical Web Experts"
        description="Connect with MWE! Get in touch for healthcare IT solutions & discuss your project. Enquiries, billing, and technical support by phone and email."
        canonical="/contact-us/"
      />
      <BrandGrid />
      <Container>
        <Row className=" mb-7 mb-lg-0">
          <Col>
            <h1 className="mt-4">Contact Us</h1>
          </Col>
        </Row>
        <Row className="justify-content-lg-between">
          <Col lg={{ span: 6, order: 1 }} className="mt-3 mt-lg-n6">
            <Alert
              variant="primary"
              className="text-white d-none d-lg-block"
              transition={false}
            >
              <strong>Have a new project?</strong> Complete the form to speak
              with one of our experts and find out how{' '}
              <strong>we can help</strong>.
            </Alert>
            <ContactUsForm />
          </Col>
          <Col lg={{ span: 4, order: 0 }} className="mt-5 mt-lg-0">
            <div className="mb-4 mt-4">
              <h5>Our Office</h5>
              <p className="m-0 font-size-sm">
                Our office is in Dallas, Texas. We are open Monday to Friday
                from 9:00am - 5:00pm EST.
              </p>
            </div>
            <div className="mb-4">
              <h6 className="mb-1">Mailing Address</h6>
              <p className="m-0 font-size-sm">
                <a
                  href="https://g.page/medicalwebexperts?share"
                  target="_blank"
                  rel="noreferrer"
                >
                  5950 Sherry Ln, Ste 405A Dallas, TX 75225
                </a>
              </p>
            </div>
            <div className="mb-4">
              <h6 className="mb-1">Phone</h6>
              <p className="m-0 font-size-sm">
                Toll-Free:{' '}
                <strong>
                  <a href="tel:18669329944">1-866-932-9944</a>
                </strong>
              </p>
            </div>
            <div className="mb-4">
              <h6 className="mb-1">Billing Inquiries</h6>
              <a
                href="mailto:billing@medicalwebexperts.com"
                className="text-muted font-size-sm"
              >
                billing@medicalwebexperts.com
              </a>
            </div>
            <div className="mb-4">
              <h6 className="mb-1">Technical Support</h6>
              <a
                href="mailto:support@medicalwebexperts.com"
                className="text-muted font-size-sm"
              >
                support@medicalwebexperts.com
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default ContactUsPage;
